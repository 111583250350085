<template>
  <el-card class="form_wrapper">
    <div class="login-form-right">
      <VerificationComponent
        ref="verification"
        :dialogVisible.sync="verificationVisibility"
        :usedAuthTypes="usedAuthTypes"
        @updateState="updateState"
        action="login"
      ></VerificationComponent>
    </div>
  </el-card>
</template>
<script>
import VerificationComponent from '@/components/security/VerificationComponent.vue';
import helper from '@/util/signinHelper.js';
export default {
  name: 'VerifyAuthentication',
  components: { VerificationComponent },
  mixins: [],
  data() {
    return {
      usedComponent: null,
      verificationVisibility: false
      // usedAuthTypes: []
    };
  },
  mounted() {
    // this.usedAuthTypes = this.$store.state.common.checkedAuthStatus.filter(
    //   item => item.path == '/verifyAuthentication' && !item.authenticated
    // );
    if (this.$store.state.common.loginStatus) {
      return this.$router.push('/home');
    }
    if (this.usedAuthTypes.length > 0) {
      this.verificationVisibility = true;
    } else {
      this.updateState(null);
    }
  },
  computed: {
    usedAuthTypes() {
      return (
        this.$store.state.common.checkedAuthStatus.filter(
          item => item.path == '/verifyAuthentication' && !item.authenticated
        ) || []
      );
    }
  },
  watch: {
    usedAuthTypes() {
      this.$forceUpdate(); // 强制更新组件 区分邮箱验证和2fa验证
    }
  },
  methods: {
    async updateState(val) {
      let lackAuthValues = this.$store.state.common.checkedAuthStatus;
      if (val) {
        lackAuthValues = this.$store.state.common.checkedAuthStatus.map(item => {
          return {
            ...item,
            namespace: 'CP_LOGIN',
            authenticated: item.type == val.type ? true : item.authenticated
          };
        });
      }
      if (lackAuthValues.every(item => item.authenticated)) {
        this.$store.state.common.loginCredentials.method == 'register'
          ? helper.register(this.$store.state.common.loginCredentials.request.a)
          : helper.signIn(
              this.$store.state.common.loginCredentials.request.userName_login,
              this.$store.state.common.loginCredentials.request.password_login
            );
      } else {
        //not using path as filter as it might be having same path for totp and email
        const tempObj = lackAuthValues.find(item => !item.authenticated);
        if (tempObj && tempObj.path != this.$router.currentRoute.path) {
          this.$router.push(tempObj.path);
        }
      }
      this.$store.commit('common/setCheckedAuthStatus', lackAuthValues);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/loginClient/login.scss';
</style>
