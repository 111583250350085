import { commonEncryptedString } from '@/util/rsa/common.rsa.upgrade'
import { md5 } from '@/util/encrypt.js'
import { apiSecurityPassed } from '@/resource'
import helper from '@/util/signinHelper'
import v1 from '@/assets/images/verification/v1.jpg'
import v2 from '@/assets/images/verification/v2.jpg'
import v3 from '@/assets/images/verification/v3.jpg'
import v4 from '@/assets/images/verification/v4.jpg'
import v5 from '@/assets/images/verification/v5.jpg'
import v6 from '@/assets/images/verification/v6.jpg'

export default {
  data() {
    return {
      needVerification: false,
      msg: '',
      imgs: [v1, v2, v3, v4, v5, v6],
      loginForm: {
        userName_login: '',
        password_login: '',
        gcaptchatoken: '',
      },
      dialogCode: '',
      twoFactorAuthVisible: false,
      loginFormRules: {
        userName_login: [
          {
            required: true,
            message: this.$t('loginClient.formValidation.emailReq'),
            trigger: 'blur',
          },
        ],
        password_login: [
          {
            required: true,
            message: this.$t('common.formValidation.pwReq'),
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {
    failedSecondTime() {
      return this.$store.state.common.failedSecondTime
    },
    twoFactorAuth() {
      return this.$store.state.common.twoFactorAuth
    },
  },
  watch: {
    twoFactorAuth() {
      this.twoFactorAuthVisible = this.twoFactorAuth
    },
  },
  methods: {
    invokeVerification() {
      this.needVerification = true
    },
    onSuccess() {
      apiSecurityPassed({
        data: commonEncryptedString('feliscatus:' + Number(new Date())),
      })
        .then(resp => {
          this.needVerification = false
          this.$store.commit('common/setFailedSecondTime', false)
        })
        .catch(err => {
          console.log(err)
        })
      this.msg = ''
    },
    onFail() {
      this.msg = this.$t('loginClient.failedMasseage')
    },
    onRefresh() {
      this.msg = ''
    },
    loginSubmit() {
      this.$refs['loginForm'].validate(valid => {
        if (valid && !this.twoFactorAuthVisible) this.checkIfAllowed()
      })
    },
    //检测到非人为操作滑动时触发的回调函数
    onAgain() {
      this.msg = this.$t('loginClient.failedMasseage')
      this.$refs.slideblock.reset()
    },
    checkIfAllowed() {
      if (!this.failedSecondTime) {
        this.login()
      } else {
        this.invokeVerification()
      }
    },
    login() {
      const userName_login = commonEncryptedString(this.loginForm.userName_login)
      const password_login = md5(this.loginForm.password_login)
      helper.signIn(userName_login, password_login)
    },
    twoFactorAuthLogin() {
      const userName_login = commonEncryptedString(this.loginForm.userName_login)
      const password_login = md5(this.loginForm.password_login)
      const token = this.dialogCode
      helper.signInwithToken(userName_login, password_login, token)
    },
  },
  mounted() {
    setTimeout(function () {
      $('.default_from_box').css('animation', 'none').css('display', 'none')
      $('.from_box').css('display', 'block')
    }, 1000)
  },
}
