<template>
  <StaticPage>
    <component :is="currentComponent"></component>
  </StaticPage>
</template>

<script>
import StaticPage from '@/components/template/staticPage/StaticPageDefault';
import LoginForm from '@/components/LoginForm';
import VerifyAuthentication from './VerifyAuthentication';
import AddAuthentication from './AddAuthentication';

export default {
  name: 'LoginClient',
  components: { StaticPage, LoginForm, VerifyAuthentication, AddAuthentication },
  computed: {
    currentComponent() {
      const currRoute = this.$route.path;
      switch (currRoute) {
        case '/verifyAuthentication':
          return VerifyAuthentication;
        case '/addAuthentication':
          return AddAuthentication;
        default:
          return LoginForm;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/loginClient/login.scss';
@import '@/assets/css/pages/loginClient/verification.scss';
</style>
