<template>
  <vDialog :visible.sync="visible" :visibleFooter="false">
    <div class="auth_wrapper">
      <el-form :model="form" ref="form" :rules="rules" @submit.native.prevent>
        <div class="text_title">{{ $t('TFAuth.dialog.title') }}</div>
        <el-form-item prop="code" class="input_wrapper2">
          <el-input v-model="form.code" data-testid="code" @keyup.enter.native="submit"></el-input>
        </el-form-item>
        <i18n path="TFAuth.dialog.contact" tag="div" class="text_title text_title2">
          <template v-slot:link>
            <span @click="showLiveChat" data-testid="livechat" class="livechat">
              {{ $t('TFAuth.dialog.link') }}
            </span>
          </template>
        </i18n>
        <div class="btn_item">
          <el-button class="bg-primary" data-testid="submit" @click="submit">
            {{ $t('common.button.submit') }}
          </el-button>
        </div>
      </el-form>
    </div>
  </vDialog>
</template>

<script>
import vDialog from '@/components/home/vDialog';

export default {
  props: {
    twoFactorAuthVisible: Boolean,
    dialogCode: String
  },
  components: { vDialog },
  data() {
    return {
      visible: false,
      form: {
        code: ''
      },
      rules: {
        code: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('common.formValidation.common')
          }
        ]
      }
    };
  },
  watch: {
    twoFactorAuthVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.$emit('update:twoFactorAuthVisible', bool);
      this.form.code = '';
      if (!bool) {
        this.$store.commit('common/setTwoFactorAuth', false);
      }
    },
    'form.code'(value) {
      this.$emit('update:dialogCode', value);
    }
  },
  methods: {
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$emit('twoFactorAuthLogin');
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/auth/twoFactorAuthentication.scss';
</style>
