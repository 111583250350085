<template>
  <el-card class="form_wrapper authentication">
    <div class="add-form-right">
      <div class="authenticator_main">
        <ChangeAuthenticatorComponent
          :action="usedComponent.factorAuth"
          :showCancel="false"
          @confirm="confirmAuthenticator"
          @reauthenticate="requireReauthenticate"
        />
      </div>
    </div>
  </el-card>
</template>
<script>
import ChangeAuthenticatorComponent from '@/components/security/ChangeAuthenticatorComponent.vue';
import { mapOperation } from '@/components/security/Security.js';
import router from '@/router';
import helper from '@/util/signinHelper.js';
import { checkObjIsEmpty } from '@/util/utils.js';
export default {
  name: 'AddAuthentication',
  components: { ChangeAuthenticatorComponent },
  mixins: [],
  data() {
    return {
      usedComponent: {}
    };
  },
  mounted() {
    if (this.$store.state.common.loginStatus) {
      this.$router.push('/home');
    }
    this.usedComponent = mapOperation('enable-auth-2fa');
    if (checkObjIsEmpty(this.usedComponent)) {
      router.push('/login');
    }
  },
  methods: {
    confirmAuthenticator(code) {
      if (code) {
        let tempAuth = this.$store.state.common.authHeaders;
        tempAuth['tCode'] = code;
        this.$store.commit('common/setAuthHeaders', tempAuth);
      }
      const lackAuthValues = this.$store.state.common.checkedAuthStatus.map(item => {
        return {
          ...item,
          verified: true,
          authenticated: item.type == 'totp' ? true : item.authenticated
        };
      });
      this.$store.commit('common/setCheckedAuthStatus', lackAuthValues);
      if (lackAuthValues.every(item => item.authenticated)) {
        this.$store.state.common.loginCredentials.method == 'register'
          ? helper.register(this.$store.state.common.loginCredentials.request.a)
          : helper.signIn(
              this.$store.state.common.loginCredentials.request.userName_login,
              this.$store.state.common.loginCredentials.request.password_login
            );
      } else {
        const tempObj = lackAuthValues.find(item => item.path != this.$router.currentRoute.path && !item.authenticated);
        if (tempObj && tempObj.path) this.$router.push(tempObj.path);
      }
    },
    requireReauthenticate() {
      this.$router.push('/login');
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/loginClient/login.scss';
.form_wrapper.authentication {
  width: auto;
  max-width: 852px;
  min-height: 640px;
  padding: 20px;
  @media (max-width: 550px) {
    width: 330px;
    /deep/ .el-card__body {
      padding: 0;
    }
  }
  /deep/ .el-checkbox__inner {
    width: 14px;
    height: 14px;
    background: #fff;
  }
  /deep/ .el-checkbox__inner:after {
    left: 4px;
    top: 2px;
    height: 6px;
    width: 3px;
  }

  /deep/ .el-form-item.is-error .el-form-item__content .el-checkbox__inner {
    border: 1px solid #e91d2c;
  }
}
</style>
<style lang="scss">
@media (max-width: 950px) {
  .add-form-right {
   .authenticator_main {
     padding: 20px;
     #changeAuthenticator {
       .process_box {
         padding: 14px 0 0 0;
         .process_items {
           div {
             padding: 0px;
             display: flex;
             flex-direction: column;
             align-items: center;
           }
           .process_desc {
             padding: 26px 0 0 0;
             max-width: max-content;
             width: 100%;
             margin-bottom: 20px;
           }
         }
       }
     }
   }
 }
}
@media (max-width: 767px) {
 #loginClient .banner {
   height: auto !important;
 }
 .add-form-right {
   .authenticator_main {
     padding: 20px;
     min-width: 250px;
     #changeAuthenticator {
       .process_box {
         padding: 24px 0 0 0;
         .process_items {
           flex-direction: column;
           align-items: center;
           padding: 0 25px;
           div {
             width: 100%;
             padding: 0px;
             display: flex;
             flex-direction: column;
             align-items: center;
           }
           .process_desc {
             padding: 26px 0 0 0;
             max-width: max-content;
             width: 100%;
             margin-bottom: 20px;
           }
           .process_desc.desc1 {
             padding-top: 0px;
           }
         }
         .tips_imgs.tips1 {
           margin-top: 0px;
         }
         .tips_imgs.tips2 {
           margin-top: 10px;
           width: 197px;
         }
       }
     }
   }
 }
}
</style>