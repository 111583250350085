<template>
  <el-card class="form_wrapper">
    <h2>{{ $t('loginClient.header') }}</h2>
    <div class="default_from_box"></div>
    <div class="from_box">
      <el-form :model="loginForm" ref="loginForm" :rules="loginFormRules">
        <div v-if="!needVerification" class="input_wrapper">
          <InputTemplate
            v-model.trim="loginForm.userName_login"
            name="userName_login"
            :label="$t('common.field.email')"
            data-testid="userName_login"
          >
          </InputTemplate>

          <InputTemplate
            v-model="loginForm.password_login"
            name="password_login"
            :label="$t('common.field.pw')"
            show-password
            @keyup.enter.native="loginSubmit"
            data-testid="password_login"
          >
          </InputTemplate>
          <el-button type="primary" @click.native="loginSubmit" data-testid="login">
            {{ $t('common.button.login') }}
          </el-button>
        </div>
        <div class="verification_wrapper" v-else>
          <slide-verify
            :l="45"
            :r="10"
            :w="270"
            :h="155"
            :slider-text="$t('common.verification.toSlide')"
            :imgs="imgs"
            ref="slideblock"
            @again="onAgain"
            @success="onSuccess"
            @fail="onFail"
            @refresh="onRefresh"
          ></slide-verify>
        </div>
        <div class="verfifyMessage" v-show="msg">{{ msg }}</div>
        <p class="forgotPass">
          <router-link to="/forgetPassword" data-testid="forgetPassword">
            {{ $t('common.button.forgotPw') }}
          </router-link>
        </p>
      </el-form>
    </div>
    <LoginDialog
      :twoFactorAuthVisible.sync="twoFactorAuthVisible"
      :dialogCode.sync="dialogCode"
      @twoFactorAuthLogin="twoFactorAuthLogin"
    ></LoginDialog>
  </el-card>
</template>

<script>
import StaticPage from '@/components/template/staticPage/StaticPageDefault';
import loginClientMixin from '@/mixins/page/loginClient';
import LoginDialog from '@/components/twoFactorAuth/LoginDialog';

export default {
  name: 'LoginForm',
  components: { StaticPage, LoginDialog },
  mixins: [loginClientMixin],
  mounted() {
    this.$store.commit('common/setAuthHeaders', {
      etxId: '',
      eCode: '',
      ptxId: '',
      pCode: '',
      tCode: ''
    });
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/loginClient/login.scss';
@import '@/assets/css/pages/loginClient/verification.scss';
</style>
